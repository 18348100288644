import { useState, useEffect } from 'react';
import { SpotWSS } from 'wf-utils/spot/spot-wss';
import AuthService from "../../Auth/auth";
import Brand from '../../Brand';
import Freespace from '../../Freespace';
import './style.css'

const areaObject = {
    CoimbatoreOffice: '2920f11b-31e8-46a2-a320-32019927005c',
}


const subscribeSpot = async (onData, templateId) => {
    const subscriptions = [{ assetId: areaObject.CoimbatoreOffice, templateId: templateId }];
    const key = await AuthService.authenticationKey();
    // console.log('print lol', key)

    const spotWs = new SpotWSS({
        subscriptions,
        wsUrl: 'wss://spot-wss.afreespace.com',
        token: key,
        onData,
    });

    return () => {
        spotWs.disposeConnection();
    };
};


function FloDsiaplyV1() {
    const [officeAreaData, setOfficeAreaData] = useState(0);
    useEffect(() => {
        const unsubscribe = subscribeSpot(SoptEventHandleData, 9);
        // console.log('unsubscribe : ', unsubscribe);
        return () => {
            console.log('unsubscribe : ');
            // unsubscribe();
        };
    }, []);


    const SoptEventHandleData = ({ data, assetId }) => {

        console.log(assetId);
        if (assetId === areaObject.CoimbatoreOffice) {
            console.log(data);
            setOfficeAreaData(data);
        }
    };

    function card(header, value, fontSize, marginTop) {
        // console.log('---------------------------- ',value);
        if (value === null) {
            return 0;
        }

        return <span style={{
            //backgroundColor,
            fontSize,
            marginTop,
            padding: '10px 20px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
            flex: 1,
            color: '#030037',
            // borderStyle: 'solid',
        }}>
            <span style={{
                // fontSize: '8vmin',
                textAlign: 'center',
                fontWeight: 500,
            }}>
                {value}
            </span>
            <span style={{
                fontSize: '4vmin',
                textAlign: 'center',
                fontWeight: 500,
            }}>
                {header}
            </span>
        </span>;
    }

    return (
        <div style={{
            padding: '10px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '90vh'
        }}>
            <div className='MainCBE'>
                <div className='Head1CBE'>CBE OFFICE</div>
                <div className='CardContainerCBE'>
                    {card('ENTRY', officeAreaData.entriesToday, '10.5vmin', '1.5vmin')}
                    {card('EXIT', officeAreaData.exitsToday, '10.5vmin', '1.5vmin')}
                    {card('OCCUPANCY', officeAreaData.occupancyNow, '15vmin', '-4.2vmin')}
                </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', maxWidth: '300px' }}>
                <Freespace />
            </div>
        </div>
    );
}

export default FloDsiaplyV1;

