
import AuthService from "../../Auth/auth";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"

function Authenticate() {
    var key = localStorage.getItem('spaceio-key')
    const params = new URLSearchParams(window.location.search)
    const navigate = useNavigate();
    const [authCode, setAuthCode] = useState(params.has('xauthcode') ? params.get('xauthcode') : null)
    useEffect(() => {
        async function fetchToken() {
            //console.log('fetching token')
            const result = await AuthService.getAuthToken(authCode);
            console.log(result.headers)

            localStorage.setItem('spaceio-key', result.headers['X-Auth-Key'] || result.headers['x-auth-key'])
            navigate('/floDisplayV1')

            //react router function to send it to selectfloor route
        }
        fetchToken();
    }, [authCode])

    // if (params.has('xauthcode')) {
    //     console.log('authcode', params.get('xauthcode'))
    //     setAuthCode(params.get('xauthcode'))
    // }

    if (!params.has('xauthcode')) {
        //console.log('redirect to authenticate page')
        window.location.href = 'https://login.afreespace.com/v2?referrer=https://flo.afreespace.com';
    }

    //   LoggedinFlag = true
    //console.log("in authenticate", key)
}



export default Authenticate;