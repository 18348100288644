import Axios from "axios";

const AuthService = {

	getAuthToken: async (authCode) => {
		try {
			const res = await Axios({
				headers: {
					"x-auth-code": authCode,
				},
				method: 'post',
				url: 'https://prodws.afreespace.com/api/login/code'
			});
			return res;
		} catch (err) {
			console.error(err);
			throw (err.response.data.error)
		}
	},

	authenticationKey: function () {
		return localStorage.getItem("spaceio-key") ? localStorage.getItem("spaceio-key") : null
	},

	login: async (username, password) => {
		try {
			const response = await Axios.post("https://prodws.afreespace.com/api/login", {
				"username": username,
				"password": password
			})
			const key = (response.headers["x-auth-key"])
			window.localStorage.setItem("spaceio-key", (key))
			return true
		} catch (err) {
			throw (err.response.data.error)
		}
	},

	logout: () => {
		window.localStorage.removeItem("spaceio-key");
	},

	getRawDevices: async (authKey, floorId) => {
		try {
			const res = await Axios.get(`https://prodws.afreespace.com/api/locations/${floorId}/raw-devices`, {
				headers: {
					"x-auth-key": authKey,
				}
			});
			return res.data;
		} catch (err) {
			console.error(err);
			throw (err.response.data.error)
		}
	},

	readDataSPOT: async (authKey, assetIdArr) => {
		try {
			const res = await Axios.post(`https://spotws.afreespace.com/assets/data/22/current`, assetIdArr, {
				headers: {
					"x-location-token": authKey,
					"authorization": process.env.REACT_APP_AUTHORIZATION_KEY,
					"Content-Type": "application/json"
				},
			},
			);
			for (let i = 0; i < res.data.length; i++) {
				const data = res.data[i];
				if (!data || !data.utcEpoch) {
					let utcTimestamp = Date.now();
					const resetData = {
						"id": assetIdArr[i],
						"setup_status": 0,
						"cal_status": 0,
						"calibration_status": 0,
						"instalation_status": 0,
						"utcEpoch": utcTimestamp,
						"test_status": [
							0,
							0,
							0,
							0,
							0
						],
						"people_count": [
							0,
							0,
							0,
							0
						]
					}
					res.data[i] = resetData;
				}
			}
			return res.data;
		} catch (err) {
			console.error(err);
			throw (err.response.data.error)
		}
	},

	writeDataToSpot: async (dataToWrite) => {
		try {
			const res = await Axios.post(`https://spotws.afreespace.com/ingest/22?asyncCall=false`, dataToWrite, {
				headers: {
					"x-location-token": AuthService.authenticationKey(),
					"authorization": process.env.REACT_APP_AUTHORIZATION_KEY,
					"Content-Type": "application/json"
				},
			},
			);
			return res.data;
		} catch (err) {
			console.error(err);
			throw (err.response.data.error)
		}
	},

	getAssetId: async (spaceId) => {
		try {
			const res = await Axios.get(`https://prodws.afreespace.com/api/devices/asset/fetch?identifier=space&id=${spaceId}`, {
				headers: {
					"licenseKey": process.env.REACT_APP_LICENSE_KEY,
				}
			});
			return res.data;
		} catch (err) {
			console.error(err);
			throw (err.response.data.error)
		}
	},

}
export default AuthService;