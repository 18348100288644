import { useState, useEffect } from 'react';
import { SpotWSS } from 'wf-utils/spot/spot-wss';
import AuthService from "../../Auth/auth";
import Brand from '../../Brand';
import Freespace from '../../Freespace';
import './style.css'

const areaObject = {
    cafeteria: 'e9f9444c-4fe8-4723-a917-f2682e4fd96b',
    officeArea: '5376ae56-9dee-4c07-bd89-1dab5817413d'
}


const subscribeSpot = async (onData, templateId) => {
    const subscriptions = [{ assetId: areaObject.cafeteria, templateId: templateId }, { assetId: areaObject.officeArea, templateId: templateId }];
    const key = await AuthService.authenticationKey();
    // console.log('print lol', key)

    const spotWs = new SpotWSS({
        subscriptions,
        wsUrl: 'wss://spot-wss.afreespace.com',
        token: key,
        onData,
    });

    return () => {
        spotWs.disposeConnection();
    };
};


function FloDsiaplyV1() {
    const [cafeteriaData, setCafeteriaData] = useState(0);
    const [officeAreaData, setOfficeAreaData] = useState(0);
    useEffect(() => {
        const unsubscribe = subscribeSpot(SoptEventHandleData, 9);
        // console.log('unsubscribe : ', unsubscribe);
        return () => {
            console.log('unsubscribe : ');
            // unsubscribe();
        };
    }, []);


    const SoptEventHandleData = ({ data, assetId }) => {

        console.log(assetId);
        if (assetId === areaObject.cafeteria) {
            console.log(data);
            setCafeteriaData(data);
        }
        else if (assetId === areaObject.officeArea) {
            console.log(data);
            setOfficeAreaData(data);
        }
    };

    function card(header, value, fontSize, marginTop) {
        // console.log('---------------------------- ',value);
        if (value === null) {
            return 0;
        }

        return <span style={{
            //backgroundColor,
            fontSize,
            marginTop,
            padding: '10px 20px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
            flex: 1,
            color: '#030037',
            // borderStyle: 'solid',
        }}>
            <span style={{
                // fontSize: '8vmin',
                textAlign: 'center',
                fontWeight: 500,
            }}>
                {value}
            </span>
            <span style={{
                fontSize: '4vmin',
                textAlign: 'center',
                fontWeight: 500,
            }}>
                {header}
            </span>
        </span>;
    }

    return <div style={{ padding: '10px' }}>
        <Brand />
        <div class='Main'>
            <div class='Head1'>
                OFFICE
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                {card('ENTRY', officeAreaData.entriesToday, '7vmin', '1vmin')}
                {card('EXIT', officeAreaData.exitsToday, '7vmin', '1vmin')}
                {card('OCCUPANCY NOW', officeAreaData.occupancyNow, '10vmin', '-2.8vmin')}
            </div>
            <div class='Head2'>
                CAFETERIA
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                {card('ENTRY', cafeteriaData.entriesToday, '7vmin', '1vmin')}
                {card('EXIT', cafeteriaData.exitsToday, '7vmin', '1vmin')}
                {card('OCCUPANCY NOW', cafeteriaData.occupancyNow, '10vmin', '-2.8vmin')}
            </div>
        </div>
        <Freespace />
    </div>
}

export default FloDsiaplyV1;

