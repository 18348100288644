import { useState, useEffect } from 'react';
import { SpotWSS } from 'wf-utils/spot/spot-wss';
import AuthService from "../../Auth/auth";
import moment from 'moment-timezone';

// import Brand from '../../Brand';
// import Freespace from '../../Freespace';
// import './style.css'

import Table from 'react-bootstrap/Table';
import 'bootstrap/dist/css/bootstrap.min.css';

const areaObject = {
    gate1: '55b59dcf-3b09-4944-ace2-304978442191',
    gate2: '9acad447-c885-4367-98bb-b8a9842417b3',
    PB_lobby: '38affa58-0edd-4be6-b419-cb97d1bce980',
    MR_lobby: '7c4bdfc6-c64b-4e9c-9c6b-2dd520587547',
}


const subscribeSpot = async (onData, templateId) => {
    const subscriptions = [
        { assetId: areaObject.gate1, templateId: templateId },
        { assetId: areaObject.gate2, templateId: templateId },
        { assetId: areaObject.PB_lobby, templateId: templateId },
        { assetId: areaObject.MR_lobby, templateId: templateId },
    ];
    const key = await AuthService.authenticationKey();
    // console.log('print lol', key)

    const spotWs = new SpotWSS({
        subscriptions,
        wsUrl: 'wss://spot-wss.afreespace.com',
        token: key,
        onData,
    });

    return () => {
        spotWs.disposeConnection();
    };
};


function FlodataFlow() {

    const [gate1, setGate1] = useState(0);
    const [gate2, setGate2] = useState(0);
    const [PB_lobby, setPBLobby] = useState(0);
    const [MR_lobby, setMRLobby] = useState(0);

    useEffect(() => {
        const unsubscribe = subscribeSpot(SoptEventHandleData, 19);
        // console.log('unsubscribe : ', unsubscribe);
        return () => {
            console.log('unsubscribe : ');
            // unsubscribe();
        };
    }, []);


    const SoptEventHandleData = ({ data, assetId }) => {
        if (assetId === areaObject.gate2) {
            console.log(data);
            setGate2(data);
        }
    };

    const getLiveTime = (timestamp) => {
        if (timestamp) {
            const date = new Date(timestamp);
            const year = date.getFullYear();
            const month = date.getMonth() + 1; // Month index starts at 0
            const day = date.getDate();
            const hours = date.getHours();
            const minutes = date.getMinutes();
            const seconds = date.getSeconds();
            const amOrPm = hours >= 12 ? 'PM' : 'AM';
            const twelveHourFormatHours = hours % 12 || 12;
            return `${year}-${month}-${day} ${twelveHourFormatHours}:${minutes}:${seconds} ${amOrPm}`
        }
        else {
            return '--'
        }

    }

    function card(header, value, fontSize, marginTop) {
        // console.log('---------------------------- ',value);
        if (value === null) {
            return 0;
        }

        return <span style={{
            //backgroundColor,
            fontSize,
            marginTop,
            padding: '10px 20px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
            flex: 1,
            color: '#030037',
            // borderStyle: 'solid',
        }}>
            <span style={{
                // fontSize: '8vmin',
                textAlign: 'center',
                fontWeight: 500,
            }}>
                {value}
            </span>
            <span style={{
                fontSize: '4vmin',
                textAlign: 'center',
                fontWeight: 500,
            }}>
                {header}
            </span>
        </span>;
    }

    return (
        <>
            <div style={{
                padding: '10px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '90vh'
            }}>
                <Table bordered size='sm'>
                    <thead style={{ backgroundColor: '#FF565E', }}>
                        <tr style={{ textAlign: 'center', color: "white", }}>
                            <th>Type</th>
                            <th>Counter</th>
                            <th>Procssed At </th>
                            <th>Delay </th>
                            <th>ENTRY</th>
                            <th>EXIT</th>
                        </tr>
                    </thead>
                    <tbody style={{ textAlign: 'center' }}>
                        <tr >
                            <td>GATE 2 Device</td>
                            <td>1</td>
                        </tr>
                        <tr>
                            <td>GATE 2 Door  </td>
                            <td>{gate2.counter}</td>
                            <td>{getLiveTime(gate2.processedAt)}</td>
                            <td>{moment.utc(gate2.utcEpoch * 1000).fromNow()}</td>
                        </tr>
                    </tbody>
                </Table>
            </div>
        </>
    );
}

export default FlodataFlow;

