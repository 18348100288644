import './App.css';
import React from 'react';
import { BrowserRouter as Router, Navigate, Route, Routes, useLocation } from 'react-router-dom';
import Authenticate from './pages/login/login';
import FloDisplay from './pages/floDisplay/floDisplayV1'
import FloDisplayCBE from './pages/floDisplay/floDisplayCBE'
import FlodataFlow from './pages/floDisplay/floDsiaplyDataFlow'


const RouteElement = ({ location, from, children }) => {
  // console.log("in route element")
  const { pathname } = location
  // console.log(pathname)
  const LoggedinFlag = window.localStorage.getItem('spaceio-key')
  return pathname === '/login' ? (LoggedinFlag ?
    <Navigate to={from} /> : (children)) : (LoggedinFlag ? children :
      <Navigate to={'/login'} state={{ from: location }} />)
}

const ProtectedRoute = ({ children, path }) => {
  // console.log("in protected route")
  const location = useLocation()
  const { from } = location.state || { from: { pathname: '/' } }
  return <RouteElement location={location} from={from} children={children} caseSensitive />
}

const App = () => {
  return <Router>
    <Routes>
      <Route index element={<Authenticate />}></Route>
      <Route path='login' element={<ProtectedRoute><Authenticate /></ProtectedRoute>}></Route>
      <Route path='floDisplayV1' element={<ProtectedRoute><FloDisplay /></ProtectedRoute>}></Route>
      <Route path='floDisplayCBE' element={<ProtectedRoute><FloDisplayCBE /></ProtectedRoute>}></Route>
      <Route path='dataFlow' element={<ProtectedRoute><FlodataFlow /></ProtectedRoute>}></Route>
    </Routes>
  </Router>
}
export default App
